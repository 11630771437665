<template>
    
</template>

<script>
export default {
  name: "ShoppingCartSearchbarLayout",
  props: {
    texts: {
      type: Object,
    },
    products:{
      type: Object,
    },
  },
  beforeMount() {
  },
};
</script>

<style scoped>
.v-ml {
  margin-left: 200px;
}

.text-module {
  font-size: 25px;
}

.text-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.border-bottom {
  border-bottom: 1px solid #d5d5d5;
}

.text-header {
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 5px;
}

.content-image {
  width: 50px;
  height: 50px;
  margin: 20px;
}

@media (max-width: 600px) {
  .hidden-component {
    display: none;
  }
}
</style>